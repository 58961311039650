@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
.registrationBlockMain .MuiRadio-colorSecondary.Mui-checked {
  color: blue !important
}
.footertextLinkMob{
  cursor: pointer;
}
.footertextLinkMobfirst{
  cursor: pointer;

}
p {
  cursor: alias;
}
.MuiInputBase-fullWidth{
  width: auto;
}
.backToHome{
  border-radius: 10px !important;
  padding: 10px 10px !important;
}
.registrationBlockMain .country-code-selector .MuiSelect-select.MuiSelect-select {
  height: 48px !important;
  width: 48px !important;
  color: white;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: green !important;
}
.registrationBlockMain .country-code-selector.Mui-focused .MuiSelect-select.MuiSelect-select {
  height: 0px !important;
  width: 0px !important;
  /* color: white !important; */
}
.MuiSelect-select:focus{
  background-color: rgba(0,0,0,0) !important;
}
.registrationBlockMain .country-code-selector .MuiSelect-selectMenu {
  height: 0 auto;
}

.registrationBlockMain .country-code-selector {
  border: 1px solid grey !important;
  border-radius: 12px !important;
  width: 22%;
  height: 52px !important;
  background-color: white;
}

.MuiInputBase-root {
  margin-left: 0.5rem;
}

.registrationBlockMain .country-code-selector .MuiSelect-icon {
  font-size: 48px !important;
  margin-top: -10px;
  border-radius: 100% !important;
  left: -4px !important;
}

.registrationBlockMain .country-code-selector .MuiSelect-selectMenu img {
  display: none !important;
}
.registrationBlockMain .country-code-selector .MuiSelect-selectMenu span{
  display: none !important;
}

.registrationBlockMain .country-code-selector::before {
  border-bottom: 0 !important;
  transition: none !important;
}

/* ToolTip */

@media screen and (max-width:550px){
  .MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #fff;
    color: black;
    width: 80% !important;
    max-width: none !important;
    border: 1px solid #000;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width:767px){
  .MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #fff;
    color: black;
    width: 400px !important;
    max-width: none !important;
    border: 1px solid #000;
    border-radius: 20px;
  }
}

@media screen and (max-width:767px) and (min-width: 550px){
  .MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #fff;
    color: black;
    width: 60% !important;
    max-width: none !important;
    border: 1px solid #000;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.MuiTooltip-arrow{
  display: none !important;
}
.MuiTooltip-popper .MuiTooltip-tooltip:after,
.MuiTooltip-popper .MuiTooltip-tooltip:before {
    content: '\00a0';
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
}

.MuiTooltip-popper .MuiTooltip-tooltip:after {
    top: 100%;
    border-color:#fff transparent transparent transparent;
    border-width: 10px;
}

.MuiTooltip-popper .MuiTooltip-tooltip:before {
    top: 100%;
    left: 49.3% !important;
    border-color: black transparent transparent transparent;
    border-width: 12px;
}
.ModalTrue{
  position: fixed;
  z-index: 100;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: #0000003a;
}
.ModalFalse{
  display: none;
}
.tooltip-heading{
  font-size: 16px;
  font-weight: 800;
  color: rgb(91, 32, 242);
  text-align: start;
}
.tooltip-para{
 font-size: 16px;
}
.Financialdata{
padding-bottom: 15px;
}
#tndc{
  cursor: pointer;
}
.country-select-code{
  width: 100% !important;
  display: flex;
  justify-content: space-between !important;
  gap:10px
}
.select-down-code{
  height:48px;
  width: min(14%, 3rem);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8C8C8C;
  border-radius: 10px;
  cursor: pointer;
  flex-shrink: 0;
}
.select-select-code img{
  object-fit: cover;
}
.input-wrapper {
  background-color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #262626;
  padding-bottom: 10px;

}
.input_tag{
  font-family: "Montserrat, Sans serif";
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}
.input-wrapper input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  width: 100%;
  margin-left: 5px;
}
.input-wrapper input:focus{
 outline: none;
}
.results-list{
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  max-height: 390px;
  overflow-y: scroll;
}
.search-result{
 padding: 10px 0px;
 display: flex;
 justify-content: space-between;
 cursor: pointer;
}
.search-result * {
  cursor: pointer;
}
.search-result:hover{
 background-color: #efefef;
}
.Flag_Country{
  display: flex;
  gap: 5px;
  align-items: center;
}
.Country_Font_Family{
  font-family: "Montserrat"
}
.Flag_Modal{
  padding: 0 !important;
}

@media screen and (max-width:767px){

    .OutlineBorder {
      width: 80% !important;
    }
    .FirstNameBox {
        width: 100% !important;
    }
    .MuiBox-root-2 {
        width: 80% !important;
    }
    .LastNameBox {
        width: 100% !important;
       
    }
    .react-tel-input .form-control {
        width: 100% !important;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined{
        font-size: 14px;
    }
    .MuiBox-root-6 {
        margin-top: 0px !important;
      }
      .MuiBox-root-8 {
        margin-top: 15px !important;
      }
      .MuiButton-label {
        justify-content: center;
      }
      
      .MuiBox-root-23  button{
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;
        flex-direction: column-reverse;
      }
      .footersection{
        width: 100% !important;
      }
      .footersection .topBorder{
        width: 100% !important;
      }
      .MuiTypography-body1 {
        font-size: 16px !important;
      }
      .formHeading {
        font-size: 20px !important;
      }
      .successFullText{
       width: 100% !important;
    }
    .successFullText p{
      font-size: 12px !important;
    }
    .successFullLogo{
      width: 155px !important;
      align-items: center
    }
   .backToHome {
    width: auto !important;
  }

  .MuiBox-root-126 {
    width: 85% !important;
  }

  .country-code-selector {
    margin-left: 10px !important;
  }

  .MuiBox-root-136 {
    width: 35% !important;
  }

  .footertextLinkMobfirst,
  .footertextLinkMob {
    font-size: 12px !important;
  }

  .blackLogoMargin {
    margin-top: 1.2rem!important;
  }

  .socialMediaLogosMob a div {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }

  .footerMargin {
    margin-top: 85px !important;
  }

}

@media screen and (max-width:500px) {
  .typoRegister.MuiTypography-root.MuiTypography-body1 {
    font-size: 14px !important;
    padding-top: 10px !important;
  }
  .MuiTypography-body1 {
    font-size: 10px !important;
    padding: 2px
  }
}

.mainconatiner {
  background: linear-gradient(white, white) padding-box, linear-gradient(to bottom right, rgba(0, 248, 162, 1), rgba(84, 18, 252, 1)) border-box;
  border-radius: 20px,
}

.WhiteBox {
  background-color: #fff;
  border-radius: 18px;
  padding: 48px 6.95%;
}

.OutlineBorder {
  background: linear-gradient(to right, rgb(0, 248, 162), rgb(84, 18, 252));
  border-radius: 20px;
  width: 45.5%;
}

.Bg-color {
  background-color: #fff;
  border-radius: 18px;
}

.Bg-color p {
  margin-top: 0px !important;
}

.facebookStyle {
  background-image: url("./../assets/facebook_new.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.facebookStyle:hover {
  filter: invert(14%) sepia(81%) saturate(4808%) hue-rotate(256deg) brightness(98%) contrast(127%);
  scale: 1.2;
}

.instagramStyle {
  background-image: url("./../assets/instagram_new.svg");
  background-repeat: no-repeat;
  background-size: contain;

}

.instagramStyle:hover {
  filter: invert(14%) sepia(81%) saturate(4808%) hue-rotate(256deg) brightness(98%) contrast(127%);
  scale: 1.2;

}

.youtubeStyle {
  background-image: url("./../assets/youtube_new.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.youtubeStyle:hover {
  filter: invert(14%) sepia(81%) saturate(4808%) hue-rotate(256deg) brightness(98%) contrast(127%);
  scale: 1.2;
}

.twitterStyle {
  background-image: url("./../assets/twitter_new.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.twitterStyle:hover {
  filter: invert(14%) sepia(81%) saturate(4808%) hue-rotate(256deg) brightness(98%) contrast(127%);
  scale: 1.2;

}

.linkedInStyle {
  background-image: url("./../assets/linkedin_new.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.linkedInStyle:hover {
  filter: invert(14%) sepia(81%) saturate(4808%) hue-rotate(256deg) brightness(98%) contrast(127%);
  scale: 1.2;
}

.logoWithh5HeadingImg {
  width: 100%;
}

@media (max-width:800px) {
  .mainconatiner {
    width: 90% !important;
    margin: 0 auto !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
}

@media(max-width:600px) {
  .footerPadding {
    padding: 0px 15px !important;
    margin: 25px 0px !important;
    /* width: 90% !important; */
    margin-left: 15px !important;
    margin-right: 15px !important;
    flex-direction: column;
  }

}

@media screen and (max-width: 500px) {
  .namiAddressMobile {
    font-size: 10px !important;
  }

  .logoWithh5HeadingImg {
    width: 99px;
    margin-bottom: 5px;
  }

  .logoWithh5HeadingH5 {
    width: 100px !important;
    font-size: 7px !important;
  }

  .socialMediaLogosMob {
    /* width: 150px !important; */
  }

  .topBorder {
    margin-bottom: 30px !important;
  }


}

@media screen and (min-width: 960px) {
  .registrationBlockMain {
    width: 60%;
    margin: auto;
  }
  .logoWithh5HeadingH5 {
    width: 125% !important;
    font-size: 12px;
  }
  .logoWithh5HeadingImg {
    width: 125% !important;
  }
  .footertextLinkMobfirst,
  .footertextLinkMob {
    font-size: 1.2rem !important;
  }
} 
@media screen and (max-width: 760px) {
  .registrationBlockMain {
    width: 90%;
    margin: auto;
  }


}
@media screen and (min-width: 760px) and (max-width: 960px) {
  .registrationBlockMain {
    width: 80%;
    margin: auto;
  }


}

