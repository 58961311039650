.RowElementInput {
    position: relative;
}


.RowElementInput .input-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
    width: 24px;
    height: 24px;
}

.RowElementInput .input-icon img {
    width: 24px;
    height: 24px;
}

.clearButton-wrapper {
    position: relative;
}
.clearButton-wrapper .subpera{
    color: #AAA;
}

.clearButton-wrapper .user-iocn {
    position: absolute;
    right: 12px;
    top: 12px;
}

.btn-icon {
    margin-right: 10px;
}

.errormsg { 
    border-radius: 12px;
    background: rgba(255, 117, 117, 0.30);
    padding: 5px;
    margin-bottom: 24px;
}

.errormsg_text {
    color: #FF7575;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.outer_wrapper {
    background-image: url(../assets/bgimg.svg);
    background-repeat: no-repeat;
    background-size: inherit;
    margin-top: 30px;
    padding-bottom: 30px;
    width: 100%;
}

.mainconatiner {
    border: 1px solid #000 !important;

}

.form_wrapper {
    width: 80%;
    margin: auto;
}

.form_wrapper input {
    padding-right: 50px !important;
}

.registrationBlockMain {
    width: 50% !important;
}
.footer-links .subpera{
    color: #AAA;
    text-decoration-line: underline;
    cursor: pointer;
}

/* --------   new password css ----------- */

.new_newpassword_wrapper .lable {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
}

.new_newpassword_wrapper .input-icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
}
.new_newpassword_wrapper .input-icon * {
    cursor: pointer;
}
.new_newpassword_wrapper .mainHeading {
    margin-bottom: 54px;
}

.typoHome,
.signupButton{
    color: #10BFB8 !important;
    border-radius: 24px !important;
    height: 48px !important;
    width: 200px !important;
    justify-content: center !important;
    border: 2px solid #10BFB8 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    font-family: "Montserrat", sans-serif !important;
    font-style: normal !important;
    text-transform: inherit !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}
.typoHome{
    width: auto !important
}
.typoHome:hover,
.signupButton:hover{
    background-color: #fff !important;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)
}


@media only screen and (max-width: 900px) {
    .outer_wrapper .inner_wrapper {
        width: 80% !important;
        margin: auto;
    }

    .inner_wrapper .registrationBlockMain {
        width: 100% !important;
    }
   
}
@media only screen and (max-width: 768px) {
    .outer_wrapper{
        width: 100% !important;
    }
}


@media only screen and (max-width: 600px) {
   
     .mainHeading{
        font-size: 18px !important;
        margin-bottom: 18px !important;
    }
    .form_wrapper input {
        height: 32px !important;
        font-size: 10px;
    }
  
    .subpara{
        font-size: 9px !important;
    }
    
    .submit_btn{
        height: unset !important;
        padding: 6px !important;
        font-size: 12px !important;
        line-height: 22px !important;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        
    }
    
    .submit_btn:hover{
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);


    }
    .submit_btn img{
        height: 24px;
    }
.new_newpassword_wrapper .lable {
    font-size: 10px !important;
    margin-bottom: 10px !important;
}
.subpera{
    font-size: 9px !important;
}
.errormsg_text{
    font-size: 8px !important;
}


}
@media only screen and (max-width: 500px) {
    .registrationBlockMain .form_wrapper {
        width: 100% !important;
    }
    .typoHome{
        padding: 12px 28px !important;
        font-size: 12px !important;
    }
  
}