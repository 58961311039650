@import url('../../blocks/email-account-login/src/EmailAccountLoginBlock.web.css');
@import url('../../blocks/email-account-registration/src/EmailAccountRegistration.web.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');
html,
body,
#root,
#root > div {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
li, ul, h1, h2, h3, h4, h5, label,p, span, div{
  cursor: context-menu;
}
a *,
a, button,
button *{
  cursor: pointer;
}
button[disabled],
button:disabled{
  cursor: context-menu;
}

.seaweed-script-regular {
  font-family: "Seaweed Script", cursive;
  font-weight: 400;
  font-style: normal;
}