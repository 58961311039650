.passwordField {
    margin-top: 10px !important;
    margin-left: 0px !important;
    height: 78%;
}

.passwordField:before {
    border-bottom: none !important;
}

.passwordField:after {
    border-bottom: none !important;
}

.emailField {
    margin-top: 10px !important;
    margin-left: 0px !important;
    height: 78%;
}

.emailField:before {
    border-bottom: none !important;
}

.emailField:after {
    border-bottom: none !important;
}

.MuiButton-root.signIN {
    text-transform: none !important;
}

.MuiButton-root.signUP {
    text-transform: none !important;
}

@media screen and (max-width: 767px) {
    .MuiBox-root-2.contain {
        width: unset !important;
    }
}

@media screen and (max-width: 767px) {

    .forgetPassword,
    .forgetEmail {
        margin-top: 15px !important;
    }

    .MuiTypography-body1.signIn {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}




